import indigo from '@material-ui/core/colors/indigo';
import pink from '@material-ui/core/colors/pink';

export default {
    palette: {
        primary: {
            light: '#42cfcf',
            main: '#049a99',
            dark: '#006e6e',
            contrastText: '#fff'
        },
        secondary: {
            light: '#b1b1b1',
            main: '#9e9e9e',
            dark: '#707070',
            contrastText: '#fff'
        },
    },
    status: {
        danger: '#df4759',
    },
    typography: {
        button: {
            fontWeight: 400,
            textAlign: 'capitalize'
        },
    },
};

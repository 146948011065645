import axios from "axios";
import ApiBase from "../constants/Api";
import SecureLS from "secure-ls"
import {LOCAL_STORAGE_USER_TOKEN, LOCAL_STORAGE_USER, LOCAL_STORAGE_ROLES} from "constants/variables"

let ls =  new SecureLS({ encodingType: 'aes' }) 

export const signIn = async (values) => {  
    await axios
    .post(`${ApiBase}/login`, values)
    .then((response)=>{
      let {user,token,roles} = response.data; 
      ls.set(LOCAL_STORAGE_USER_TOKEN,token);
      ls.set(LOCAL_STORAGE_USER, user);
      ls.set(LOCAL_STORAGE_ROLES, roles);
       return true;
    })
    .catch(function(error) {
      return false;
    }); 
      
  }

export const authUser = ()=> ls.get(LOCAL_STORAGE_USER_TOKEN) ? true : false

export const getUser = () =>  ls.get(LOCAL_STORAGE_USER) ? ls.get(LOCAL_STORAGE_USER) : false

export const getRoles = () => ls.get(LOCAL_STORAGE_ROLES) ? ls.get(LOCAL_STORAGE_ROLES) : false

export const getToken = () =>  ls.get(LOCAL_STORAGE_USER_TOKEN) ? "Bearer " + ls.get(LOCAL_STORAGE_USER_TOKEN).access_token : false

export const logout = () =>
{
  ls.clear()
}

export const switchToken = () => {
  const tokenObject =  ls.get(LOCAL_STORAGE_USER_TOKEN)
  tokenObject.access_token = tokenObject.refresh_token
  ls.set(LOCAL_STORAGE_USER_TOKEN,tokenObject)
  return tokenObject.access_token;
}

export const authorizationHeader = (token) => ({
  headers: {
    'Authorization': token || getToken(),
  }
})

export const authorizationHeaderDownloadFiles = () => ({
  headers: {
    'Authorization': getToken(),
  },
  responseType: "blob"
})

import React from 'react';
import ReactDOM from 'react-dom';
import *  as serviceWorkerRegistration from './serviceWorkerRegistration';

const rootEl = document.getElementById('app-site');

// Create a reusable render method that we can call more than once
let render = () => {
  // Dynamically import our main App component, and render it
  const MainApp = require('./MainApp').default;
  ReactDOM.render(
    <MainApp/>,
    rootEl
  );
};

if (module.hot) {
  module.hot.accept('./MainApp', () => {
    const MainApp = require('./MainApp').default;
    render(
      <MainApp/>,
      rootEl
    );
  });
}

render();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register('service-worker.js');

// Then later, request a one-off sync:
navigator.serviceWorker.ready.then((swRegistration) => {
  return swRegistration.sync.register('geolocation-background');
});

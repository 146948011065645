import React from 'react';
import {Link} from 'react-router-dom'
import {connect} from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Redirect} from 'react-router-dom'
import Swal from "sweetalert2";
import {
  hideMessage,
  showAuthLoader,
  userSignIn,
} from 'actions/Auth';
import {signIn,authUser} from "services/authAPI";

class SignIn extends React.Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      auth:false,
      loader:false
    }
  }


  componentDidUpdate() {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 100);
    }
    if (authUser()) {
      this.props.history.push('/');
    }
  }

  handleSubmit= async (values)=> {
    this.setState({...this.state,loader:true})
     await signIn(values).then((response)=>{
       if(!response && !authUser())
        Swal.fire({title:"Error", text: "Usuario o contraseña incorrecta.",confirmButtonText:"Aceptar",confirmButtonColor: "#049a99"})
     }
       
     );
     
      this.setState({...this.state, loader:false, auth: authUser()})
    
     
  }

  render() {
    const {
      email,
      password,
      auth
    } = this.state;
    const {showMessage, alertMessage} = this.props;
    return (
      <div
        className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
          {auth && <Redirect to={"/"}/>}
        <div className="app-login-main-content">

          <div className="app-logo-content d-flex align-items-center justify-content-center bg-primary">
            <Link className="logo-lg" to="/" title="Jambo">
              <img src={require("assets/images/gp.png")} alt="gpm" title="GPMUTUAL" height="130px" />
            </Link>
          </div>

          <div className="app-login-content">
            <div className="app-login-header mb-4">
              <h1><IntlMessages id="appModule.logIn"/></h1>
            </div>

            <div className="app-login-form">
              <form>
                <fieldset>
                  <TextField
                    label={"Correo electrónico"}
                    fullWidth
                    onChange={(event) => this.setState({email: event.target.value})}
                    defaultValue={email}
                    margin="normal"
                    className="mt-1 my-sm-3"
                  />
                  <TextField
                    type="password"
                    label={"Contraseña"}
                    fullWidth
                    onChange={(event) => this.setState({password: event.target.value})}
                    defaultValue={password}
                    margin="normal"
                    className="mt-1 my-sm-3"
                  />

                  <div className="mb-3 d-flex align-items-center justify-content-between">
                    <Button onClick={() => {
                      //this.props.showAuthLoader();
                      this.handleSubmit({email, password});
                    }} variant="contained" className="bg-primary text-white">
                      <IntlMessages id="appModule.logIn"/>
                    </Button>
                  </div>

                </fieldset>
              </form>
            </div>
          </div>

        </div>
        {
          this.state.loader &&
          <div className="loader-view">
            <CircularProgress/>
          </div>
        }
        {showMessage && NotificationManager.error(alertMessage)}
        <NotificationContainer/>
      </div>
    );
  }
}

const mapStateToProps = ({auth}) => {
  const {loader, alertMessage, showMessage, authUser} = auth;
  return {loader, alertMessage, showMessage, authUser}
};

export default connect(mapStateToProps, {
  userSignIn,
  hideMessage,
  showAuthLoader,
})(SignIn);

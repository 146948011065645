import axios from "axios";
import ApiBase from "../constants/Api";
import {authorizationHeader} from './authAPI'

export const updateCoords = async (id,coords) =>{
    return await axios
    .put(
      `${ApiBase}/siniestros/coords/${id}`,
      coords,
      authorizationHeader()
    )
    .then()
    .catch(function(error) {
      console.log(error);
    });
 }


 export const getCoords = async (id) => {
    return await axios
    .get(`${ApiBase}/siniestros/coords/${id}`,authorizationHeader())
    .then()
    .catch((error) => {
      console.log(error);
    });
}
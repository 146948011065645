import React from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";

const getDisplayString = (sub) => {
  const arr = sub.split("-");
  if (arr.length > 1) {
    if(arr.length === 3){
      return (
        arr[0].charAt(0).toUpperCase() +
        arr[0].slice(1) +
        " " +
        arr[1].charAt(0).toUpperCase() +
        arr[1].slice(1) +
        " " +
        arr[2].charAt(0).toUpperCase() +
        arr[2].slice(1)
      );
    }
    return (
      arr[0].charAt(0).toUpperCase() +
      arr[0].slice(1) +
      " " +
      arr[1].charAt(0).toUpperCase() +
      arr[1].slice(1)
    );
  } else {
    return sub.charAt(0).toUpperCase() + sub.slice(1);
  }
};

// const ContainerHeader = ({title, match}) => {
//   const path = match.path.substr(1);
//   const subPath = path.split('/');
//   return (
//     <div className="page-heading d-sm-flex justify-content-sm-between align-items-sm-center">
//       <h2 className="title mb-3 mb-sm-0">{title}</h2>

//       <Breadcrumb className="mb-0" tag="nav">
//         {subPath.map((sub, index) => {
//             return <BreadcrumbItem active={subPath.length === index + 1}
//                                    tag={subPath.length === index + 1 ? "span" : "a"} key={index}
//                                    href={getUrlString(path, sub, index)}>{getDisplayString(sub)}</BreadcrumbItem>
//           }
//         )}
//       </Breadcrumb>
//     </div>
//   )
// };

const ContainerHeader = ({ title, routeLink, linkLabel = "undefined", match }) => {
  const path = match.url.substr(1);
  const subPath = path.split("/");
  return (
    <div className="page-heading d-sm-flex justify-content-sm-between align-items-sm-center mb-3">
      <h2 className="title mb-3 mb-sm-0">{title}</h2>

       {!routeLink && <Breadcrumb className="mb-0 p-2" tag="nav">
         {subPath.map((sub, index) => {
            return <BreadcrumbItem active={subPath.length === index + 1}
                                   tag={subPath.length === index + 1 ? "span" : "a"} key={index}
                                   className="text-primary"
                                   >{getDisplayString(sub)}</BreadcrumbItem>
          }
        )}
      </Breadcrumb>}

      {routeLink && (
        <Link to={routeLink} className={"btn p-0 m-0"}>
          <Button className="bg-primary text-white" variant="contained">
            {linkLabel}
          </Button>
        </Link>
      )}
    </div>
  );
};

export default ContainerHeader;

import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { getRoles } from "services/authAPI.js";

import IntlMessages from "util/IntlMessages";
import CustomScrollbars from "util/CustomScrollbars";

class SidenavContent extends Component {
  componentDidMount() {
    const { history } = this.props;
    const that = this;
    const pathname = `${history.location.pathname}`; // get current path

    const menuLi = document.getElementsByClassName("menu");
    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function(event) {
        const parentLiEle = that.closest(this, "li");
        if (menuLi[i].classList.contains("menu") && parentLiEle !== null) {
          event.stopPropagation();

          if (menuLi[i].classList.contains("open")) {
            menuLi[i].classList.remove("open", "active");
          } else {
            menuLi[i].classList.add("open", "active");
          }
        } else {
          for (let j = 0; j < menuLi.length; j++) {
            const parentLi = that.closest(this, "li");
            if (
              menuLi[j] !== this &&
              (parentLi === null || !parentLi.classList.contains("open"))
            ) {
              menuLi[j].classList.remove("open");
            } else {
              if (menuLi[j].classList.contains("open")) {
                menuLi[j].classList.remove("open");
              } else {
                menuLi[j].classList.add("open");
              }
            }
          }
        }
      };
    }

    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("open");
      } else {
        this.closest(activeLi, "li").classList.add("open");
      }
    } catch (error) {}
  }

  componentWillReceiveProps(nextProps) {
    const { history } = nextProps;
    const pathname = `${history.location.pathname}`; // get current path

    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("open");
      } else {
        this.closest(activeLi, "li").classList.add("open");
      }
    } catch (error) {}
  }

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      [
        "matches",
        "webkitMatchesSelector",
        "mozMatchesSelector",
        "msMatchesSelector",
        "oMatchesSelector",
      ].some(function(fn) {
        if (typeof document.body[fn] === "function") {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) {}

    return null;
  }

  evaluateRoles(validRole) {
    let roles = getRoles();
    let finded = false;
    roles.map((role) => {
      if (role === validRole) finded = true;
    });
    return finded;
  }

  render() {
    return (
      <CustomScrollbars className=" scrollbar">
        <ul className="nav-menu">
          <li className="nav-header">
            <IntlMessages id="sidebar.menu" />
          </li>
          {(this.evaluateRoles("Ajustador") ||
            this.evaluateRoles("Grua") ||
            this.evaluateRoles("Hospital") ||
            this.evaluateRoles("Taller") ||
            this.evaluateRoles("Medico Particular")) && (
              <li className="menu no-arrow">
                <NavLink to="/app/siniestros">
                  <i className="zmdi zmdi-directions-car zmdi-hc-fw" />
                  <span className="nav-text">Siniestros asignados</span>
                </NavLink>
              </li>
            )}
          {(this.evaluateRoles("Cabina") ||
            this.evaluateRoles("Supervision")) && (
            <li className="menu no-arrow">
              <NavLink to="/app/siniestros">
                <i className="zmdi zmdi-directions-car zmdi-hc-fw" />
                <span className="nav-text">Siniestros</span>
              </NavLink>
            </li>
          )}
          {(this.evaluateRoles("Cabina") ||
            this.evaluateRoles("Supervision")) && (
            <li className="menu no-arrow">
              <NavLink to="/app/proveedores/lista">
                <i className="zmdi zmdi-accounts zmdi-hc-fw" />
                <span className="nav-text">Proveedores</span>
              </NavLink>
            </li>
          )}
          {(this.evaluateRoles("Pagos") ||
            this.evaluateRoles("Contabilidad") ||
            this.evaluateRoles("Supervision")) && (
            <li className="menu no-arrow">
              <NavLink to="/app/pagos">
                <i className="zmdi zmdi-money zmdi-hc-fw" />
                <span className="nav-text">Pagos</span>
              </NavLink>
            </li>
          )}
          {(this.evaluateRoles("Valuacion") ||
            this.evaluateRoles("Supervision")) && (
            <li className="menu no-arrow">
              <NavLink to="/app/pagos/valuacion">
                <i className="zmdi zmdi-receipt zmdi-hc-fw" />
                <span className="nav-text">Valuación</span>
              </NavLink>
            </li>
          )}
          {(this.evaluateRoles("Juridico") ||
            this.evaluateRoles("Supervision") ||
            this.evaluateRoles("Abogado")) && (
            <li className="menu no-arrow">
              <NavLink to="/app/juridico">
                <i className="zmdi zmdi-case zmdi-hc-fw" />
                <span className="nav-text">Jurídico</span>
              </NavLink>
            </li>
          )}
        </ul>
      </CustomScrollbars>
    );
  }
}

export default withRouter(SidenavContent);

import React from "react";

/*Components*/
import ContainerHeader from "components/ContainerHeader";
import { Route, Switch, Redirect } from "react-router-dom";

export const Home = (props) => {
  return (
    <>
      <div className="app-wrapper">
        <ContainerHeader match={props.match} title="Inicio" />
        <div className="col-12 p-3 container-fluid shadow-lg card CustomHome-div">
          <h1 className="mt-3 text-center">Bienvenido</h1>
          <div className="col-sm-12 d-flex justify-content-center">
            <img
              src={require("assets/images/favicon-gp.png")}
              alt="gpm"
              title="GPMUTUAL"
              className="CustomHome-image"
            />
          </div>
        </div>
      </div>
    </>
  );
};
